import { useEffect } from "react";
import NotistackProvider from "./components/NotistackProvider";
import { ProgressBarStyle } from "./components/ProgressBar";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import ThemeColorPresets from "./components/ThemeColorPresets";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import Settings from "./components/settings";
import useAuth from "./hooks/useAuth";

import Router from "./routes";

import ThemeProvider from "./theme";
import ErrorBoundary from "./components/error-boundary";
import "./style.css";

export default function App() {
  const { getUser, isAuthenticated, user } = useAuth();

  useEffect(() => {
    if (isAuthenticated && user !== null) {
      getUser();
      return;
    }
  }, [isAuthenticated]);

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <NotistackProvider>
            <MotionLazyContainer>
              <ProgressBarStyle />
              <Settings />
              <ScrollToTop />
              <ErrorBoundary>
                <Router />
              </ErrorBoundary>
            </MotionLazyContainer>
          </NotistackProvider>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
